<template>
  <section class="section container">
    <b-icon icon="align-left" /> align-left<br />
    <b-icon icon="angle-down" /> angle-down<br />
    <b-icon icon="angle-left" /> angle-left<br />
    <b-icon icon="angle-right" /> angle-right<br />
    <b-icon icon="arrow-up" /> arrow-up<br />
    <b-icon icon="arrow-down" /> arrow-down<br />
    <b-icon icon="at" /> at<br />
    <b-icon icon="bars" /> bars<br />
    <b-icon icon="ban" /> ban<br />
    <b-icon icon="bold" /> bold<br />
    <b-icon icon="calendar" /> calendar<br />
    <b-icon icon="calendar-exclamation" /> calendar-exclamation<br />
    <b-icon icon="calendar-times" /> calendar-times<br />
    <b-icon icon="calendar-week" /> calendar-week<br />
    <b-icon icon="check" /> check<br />
    <b-icon icon="check-circle" /> check-circle<br />
    <b-icon icon="chevron-circle-down" /> chevron-circle-down<br />
    <b-icon icon="chevron-circle-left" /> chevron-circle-left<br />
    <b-icon icon="chevron-circle-right" /> chevron-circle-right<br />
    <b-icon icon="chevron-circle-up" /> chevron-circle-up<br />
    <b-icon icon="chevron-down" /> chevron-down<br />
    <b-icon icon="chevron-up" /> chevron-up<br />
    <b-icon icon="chevron-left" /> chevron-left<br />
    <b-icon icon="chevron-right" /> chevron-right<br />
    <b-icon icon="clock" /> clock<br />
    <b-icon icon="code" /> code<br />
    <b-icon icon="comment-plus" /> comment-plus<br />
    <b-icon icon="comment-alt-plus" /> comment-alt-plus<br />
    <b-icon icon="comment-alt" /> comment-alt<br />
    <b-icon icon="crown" /> crown<br />
    <b-icon icon="edit" /> edit<br />
    <b-icon icon="ellipsis-h" /> ellipsis-h<br />
    <b-icon icon="envelope" /> envelope<br />
    <b-icon icon="exchange-alt" /> exchange-alt<br />
    <b-icon icon="exclamation-circle" /> exclamation-circle<br />
    <b-icon icon="exclamation-triangle" /> exclamation-triangle<br />
    <b-icon icon="file" /> file<br />
    <b-icon icon="file-code" /> file-code<br />
    <b-icon icon="flag" /> flag<br />
    <b-icon icon="globe" /> globe<br />
    <b-icon icon="grip-vertical" /> grip-vertical<br />
    <b-icon icon="heading" /> heading<br />
    <b-icon icon="info-circle" /> info-circle<br />
    <b-icon icon="italic" /> italic<br />
    <b-icon icon="list-ol" /> list-ol<br />
    <b-icon icon="list-ul" /> list-ul<br />
    <b-icon icon="lock" /> lock<br />
    <b-icon icon="long-arrow-alt-right" /> long-arrow-alt-right<br />
    <b-icon icon="map-marker-alt" /> map-marker-alt<br />
    <b-icon icon="minus-square" /> minus-square<br />
    <b-icon icon="paragraph" />  paragraph<br />
    <b-icon icon="pencil-alt" /> pencil-alt<br />
    <b-icon icon="plus" /> plus<br />
    <b-icon icon="plus-circle" /> plus-circle<br />
    <b-icon icon="question-circle" /> question-circle<br />
    <b-icon icon="quote-left" /> quote-left<br />
    <b-icon icon="redo" /> redo<br />
    <b-icon icon="remove-format" /> remove-format<br />
    <b-icon icon="search" /> search<br />
    <b-icon icon="sign-out-alt" /> sign-out-alt<br />
    <b-icon icon="search-location" /> search-location<br />
    <b-icon icon="sparkles" /> sparkles<br />
    <b-icon icon="star" /> star<br />
    <b-icon icon="sticky-note" /> sticky-note<br />
    <b-icon icon="stopwatch" /> stopwatch<br />
    <b-icon icon="strikethrough" /> strikethrough<br />
    <b-icon icon="times-circle" /> times-circle<br />
    <b-icon icon="thumbs-up" /> thumbs-up<br />
    <b-icon icon="underline" /> underline<br />
    <b-icon icon="undo" /> undo<br />
    <b-icon icon="user" /> user<br />
    <b-icon icon="user-check" /> user-check<br />
    <b-icon icon="user-friends" /> user-friends<br />
    <b-icon icon="user-times" /> user-times<br />
  </section>
</template>

<script>
export default {
  name: "Icons"
};
</script>
